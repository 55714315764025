<template>
  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '90%' }"
    header="Edit Company"
    :modal="true"
    class="p-fluid"
    :maximizable="true"
    @hide="closeDialog"
  >
    <div class="field">
      <label for="name">Name</label>
      <InputText
        id="name"
        v-model.trim="form.name"
        @blur="v$.form.name.$touch"
        autofocus
        :class="{ 'p-invalid': !form.name }"
      />
      <small class="p-error" v-if="!form.name">Name is required.</small>
    </div>

    <div class="field">
      <label for="company-type">Company type</label>

      <Dropdown
        v-model="form.companyTypeId"
        :options="companyTypes"
        optionLabel="name"
        optionValue="companyTypeId"
        placeholder="Company types"
        :class="{ 'p-invalid': !form.companyTypeId }"
        :filter="true"
        :loading="loadingCompanyTypes"
      />
    </div>

    <div class="field">
      <label for="Country">Country</label>

      <Dropdown
        v-model="form.countryId"
        :options="countries"
        optionLabel="name"
        optionValue="countryId"
        placeholder="Country"
        :class="{ 'p-invalid': !form.countryId }"
        :filter="true"
        :loading="loadingCountries"
      >
      </Dropdown>
    </div>

    <div class="field">
      <label for="address">Address</label>
      <InputText
        id="address"
        v-model.trim="form.address"
        autofocus
      />
    </div>

    <div class="field">
      <label for="numberEmployees">Number of employees</label>
      <InputNumber
        id="numberEmployees"
        v-model="form.numberEmployees"
        autofocus
        suffix=" employees"
      />
    </div>

    <div class="field">
      <label for="description">Description</label>
      <InputText
        id="description"
        v-model.trim="form.description"
        autofocus
      />
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <Button
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        :loading="editIsLoading"
        :disabled="this.v$.form.$invalid || editIsLoading"
        @click="edit"
      />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'EditCompany',
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
    InputNumber,
  },
  props: ['selectedElement'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      loadingCompanyTypes: false,
      loadingCountries: false,
      editIsLoading: false,
      showDialog: false,
      form: {
        name: null,
        companyTypeId: null,
        countryId: 1,
        description: null,
        address: null,
        numberEmployees: null,
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        companyTypeId: { required },
        countryId: { required },
      },
    };
  },
  async mounted() {
    await this.getCompanyTypes();
    await this.getCountries();
  },
  computed: {
    companyTypes() {
      return this.$store.getters.obtainCompanyTypes;
    },
    countries() {
      return this.$store.getters.obtainCountries;
    },
  },
  methods: {
    async getCompanyTypes() {
      try {
        this.loadingCompanyTypes = true;
        await this.$store.dispatch('obtainCompanyTypes');
        this.loadingCompanyTypes = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingCompanyTypes = false;
      }
    },
    async getCountries() {
      try {
        this.loadingCountries = true;
        await this.$store.dispatch('obtainCountries');
        this.loadingCountries = false;
      } catch (error) {
        toast.error(error.message);
        this.loadingCountries = false;
      }
    },
    closeDialog() {
      this.showDialog = false;
      this.form = {
        name: null,
        companyTypeId: null,
        countryId: null,
        description: null,
        address: null,
        numberEmployees: null,
      };
      this.$emit('closeDialog', 'edit');
    },
    async edit() {
      try {
        if (!this.v$.form.$invalid) {
          this.editIsLoading = true;
          await this.$store.dispatch('modifyCompany', {
            companyId: this.selectedElement.companyId,
            ...this.form,
          });
          this.editIsLoading = false;
          // toast.success('Successfully updated.');
          this.closeDialog();
        } else {
          toast.error('Wrong country data.');
        }
      } catch (error) {
        this.editIsLoading = false;
        console.error(error);
        toast.error(error);
      }
    },
  },
  watch: {
    async selectedElement(newVal) {
      if (newVal) {
        this.showDialog = true;
        this.form = Object.assign({}, this.selectedElement);
      }
    },
  },
};
</script>
